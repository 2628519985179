<template>
<div>
    <b-row class="justify-content-center">
        <b-col lg="5">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA EL ÁREA DE TAREAS
                    </span>
                    <hr>
                    <CChartPie :options="optionsEstadistica" class="my-2" :datasets="dataSetsEstadistica" :labels="labelEstadistica" />
                </CCardBody>
            </CCard>
        </b-col>
        <b-col lg="5">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de tareas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col v-if=" checkPermissions('013-TAR-CAL','c')==1"  md="4" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalRegistrarTarea = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nueva tarea</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col lg="10">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-calendar-alt fa-md"></i> <span class="h5"> Agenda de Tareas</span>

                    <b-button-group class="float-right" size="sm">
                        <b-button @click="CambiarFrencuencia(1)" :class="calenFrecuen==1 ? 'btn-blue' : 'btn-light'">Mes</b-button>
                        <b-button @click="CambiarFrencuencia(2)" :class="calenFrecuen==2 ? 'btn-blue' : 'btn-light'">Semana</b-button>
                        <b-button @click="CambiarFrencuencia(3)" :class="calenFrecuen==3 ? 'btn-blue' : 'btn-light'">Día</b-button>
                    </b-button-group>
                </CCardHeader>
                <CCardBody>
                    <FullCalendar ref="calendar" :options="calendarOptions" />
                </CCardBody>
            </CCard>
        </b-col>

    </b-row>
    <b-row class="justify-content-center">
        <b-col lg="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-flag fa-md"></i> <span class="h5"> Tareas pendientes</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">

                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaTareas.filter(x => x.subEstado == 1)" :fields="camposTareasPendientes" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button  v-if=" checkPermissions('013-TAR-CAL','u')==1"  size="sm" class="mr-1 mb-1" variant="dark" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarTarea(param)">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button v-if=" checkPermissions('013-TAR-CAL','u')==1"  size="sm" v-c-tooltip.hover.click="'Realizado'" variant="success" @click="actualizarEstadoTarea(param)" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-check my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button  v-if=" checkPermissions('013-TAR-CAL','d')==1" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarTarea(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                </template>
                                <template v-slot:cell(fechaInicio)="data">
                                    <span v-if="data.item.diaCompleto">{{data.item.inicio}}</span>
                                    <span v-else-if="!data.item.diaCompleto">{{data.item.inicio.substr(0,10)}} {{data.item.inicio.split("T")[1].split("-")[0]}}</span>
                                </template>
                                <template v-slot:cell(prioridad)="data">
                                    <h5>
                                        <b-badge v-if="data.value == 1" class="text-white" style="background: #b0b0b0;">Baja</b-badge>
                                        <b-badge v-else-if="data.value == 2" class="text-white" style="background: #5e96ea;">Normal</b-badge>
                                        <b-badge v-else-if="data.value == 3" class="text-white" style="background: #ec5b58;">Alta</b-badge>
                                    </h5>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
        <b-col lg="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-flag fa-md"></i> <span class="h5"> Tareas realizadas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter2" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaTareas.filter(x => x.subEstado == 2)" :fields="camposTareasRealizadas" :current-page="paginaActual2" :per-page="porPagina2" :filter="filter2" :filter-included-fields="filterOn2" @filtered="onFiltered2" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">

                                    <b-button  v-if=" checkPermissions('013-TAR-CAL','u')==1"  size="sm" class="mr-1 mb-1" variant="dark" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarTarea(param)">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button   v-if=" checkPermissions('013-TAR-CAL','d')==1" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarTarea(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                                <template v-slot:cell(fechaInicio)="data">
                                    <span v-if="data.item.diaCompleto">{{data.item.inicio}}</span>
                                    <span v-else-if="!data.item.diaCompleto">{{data.item.inicio.substr(0,10)}} {{data.item.inicio.split("T")[1].split("-")[0]}}</span>
                                </template>
                                <template v-slot:cell(prioridad)="data">
                                    <h5>
                                        <b-badge v-if="data.value == 1" class="text-white" style="background: #b0b0b0;">Baja</b-badge>
                                        <b-badge v-else-if="data.value == 2" class="text-white" style="background: #5e96ea;">Normal</b-badge>
                                        <b-badge v-else-if="data.value == 3" class="text-white" style="background: #ec5b58;">Alta</b-badge>
                                    </h5>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual2" :total-rows="filasTotales2" :per-page="porPagina2" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :show.sync="modalRegistrarTarea" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva tarea </span>
            </h6>
            <CButtonClose @click="modalRegistrarTarea = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarTarea)">
                <b-row>
                    <b-col lg="6">
                        <validation-provider name="tarea" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tarea:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre de tarea" v-model="datosTarea.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="empresa" v-slot="validationContext">
                            <b-form-group label="Empresa:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la empresa" v-model="datosTarea.empresa"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="localidad" v-slot="validationContext">
                            <b-form-group label="Localidad:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la localidad" v-model="datosTarea.localidad"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="contácto" v-slot="validationContext">
                            <b-form-group label="Contácto:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el contácto" v-model="datosTarea.contacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="email" :rules="{email:true}" v-slot="validationContext">
                            <b-form-group label="Email:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el email" v-model="datosTarea.email"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="teléfono" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosTarea.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="fecha de inicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de inicio:" class="mb-2">
                                <b-form-input size="md" type="date" :max="datosTarea.fechaFin" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de inicio" v-model="datosTarea.fechaInicio"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="fecha de fin" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de fin:" class="mb-2">
                                <b-form-input size="md" type="date" :min="datosTarea.fechaInicio" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de fin" v-model="datosTarea.fechaFin"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <!-- <validation-provider name="dia completo" :rules="{required: true}" v-slot="validationContext"> -->
                        <b-form-group label="Dia completo:" class="my-2">
                            <b-form-radio-group small plain :disabled="disabledRG" v-model="datosTarea.diaCompleto" :options="radioDiaCompleto"></b-form-radio-group>
                            <!-- <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
                        </b-form-group>
                        <!-- </validation-provider> -->
                    </b-col>
                    <b-col lg="4" v-if="!datosTarea.diaCompleto">
                        <validation-provider name="hora de inicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora de inicio:" class="mb-2">
                                <!-- <b-form-timepicker v-model="datosTarea.horaFin" locale="es" :state="getValidationState(validationContext)" placeholder="Ingrese la hora de fin"></b-form-timepicker> -->
                                <b-form-input size="md" type="time" @change="validarTimes()" v-model="datosTarea.horaInicio" :state="getValidationState(validationContext)" placeholder="00:00"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4" v-if="!datosTarea.diaCompleto">
                        <validation-provider name="hora de fin" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora de fin:" class="mb-2">
                                <b-form-input size="md" type="time" @change="validarTimes()" :state="getValidationState(validationContext)" placeholder="00:00" v-model="datosTarea.horaFin"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col :lg="!datosTarea.diaCompleto ? '4' : '6'">
                        <validation-provider name="estado" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Estado:" class="mb-2">
                                <b-form-select :state="getValidationState(validationContext)" :options="comboEstado" value-field="idEstado" text-field="descripcion" v-model="datosTarea.subEstado" size="md">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col :lg="!datosTarea.diaCompleto ? '4' : '6'">
                        <validation-provider name="prioridad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Prioridad:" class="mb-2">
                                <b-form-select :state="getValidationState(validationContext)" :options="comboPrioridad" value-field="idPrioridad" text-field="descripcion" v-model="datosTarea.prioridad" size="md">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col :lg="!datosTarea.diaCompleto ? '8' : '12'">
                        <validation-provider name="observaciones" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosTarea.observaciones" :state="getValidationState(validationContext)" placeholder="Ingrese la observacion" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12" class="text-right my-2">
                        <b-button size="md"  v-if=" checkPermissions('013-TAR-CAL','c')==1"  :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarTarea = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarTarea" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>

            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar tarea</span></h6>

            <CButtonClose @click="modalActualizarTarea = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarTarea)">
                <b-row>
                    <b-col lg="6">
                        <validation-provider name="tarea" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tarea:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre de tarea" v-model="datosActualizarTarea.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="empresa" v-slot="validationContext">
                            <b-form-group label="Empresa:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la empresa" v-model="datosActualizarTarea.empresa"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="localidad" v-slot="validationContext">
                            <b-form-group label="Localidad:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la localidad" v-model="datosActualizarTarea.localidad"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="contácto" v-slot="validationContext">
                            <b-form-group label="Contácto:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el contácto" v-model="datosActualizarTarea.contacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="email" :rules="{email:true}" v-slot="validationContext">
                            <b-form-group label="Email:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el email" v-model="datosActualizarTarea.email"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="teléfono" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosActualizarTarea.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="fecha de inicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de inicio:" class="mb-2">
                                <b-form-input size="md" type="date" :max="datosActualizarTarea.fechaFin" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de inicio" v-model="datosActualizarTarea.fechaInicio"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="fecha de fin" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de fin:" class="mb-2">
                                <b-form-input size="md" type="date" :min="datosActualizarTarea.fechaInicio" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de fin" v-model="datosActualizarTarea.fechaFin"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <!-- <validation-provider name="dia completo" :rules="{required: true}" v-slot="validationContext"> -->
                        <b-form-group label="Dia completo:" class="my-2">
                            <b-form-radio-group small plain :disabled="disabledRG" v-model="datosActualizarTarea.diaCompleto" :options="radioDiaCompleto"></b-form-radio-group>
                            <!-- <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
                        </b-form-group>
                        <!-- </validation-provider> -->
                    </b-col>
                    <b-col lg="4" v-if="!datosActualizarTarea.diaCompleto">
                        <validation-provider name="hora de inicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora de inicio:" class="mb-2">
                                <!-- <b-form-timepicker v-model="datosActualizarTarea.horaFin" locale="es" :state="getValidationState(validationContext)" placeholder="Ingrese la hora de fin"></b-form-timepicker> -->
                                <b-form-input size="md" type="time" @change="validarTimes()" v-model="datosActualizarTarea.horaInicio" :state="getValidationState(validationContext)" placeholder="00:00"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4" v-if="!datosActualizarTarea.diaCompleto">
                        <validation-provider name="hora de fin" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora de fin:" class="mb-2">
                                <b-form-input size="md" type="time" @change="validarTimes()" :state="getValidationState(validationContext)" placeholder="00:00" v-model="datosActualizarTarea.horaFin"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col :lg="!datosActualizarTarea.diaCompleto ? '4' : '6'">
                        <validation-provider name="estado" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Estado:" class="mb-2">
                                <b-form-select :state="getValidationState(validationContext)" :options="comboEstado" value-field="idEstado" text-field="descripcion" v-model="datosActualizarTarea.subEstado" size="md">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col :lg="!datosActualizarTarea.diaCompleto ? '4' : '6'">
                        <validation-provider name="prioridad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Prioridad:" class="mb-2">
                                <b-form-select :state="getValidationState(validationContext)" :options="comboPrioridad" value-field="idPrioridad" text-field="descripcion" v-model="datosActualizarTarea.prioridad" size="md">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col :lg="!datosActualizarTarea.diaCompleto ? '8' : '12'">
                        <validation-provider name="observaciones" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarTarea.observaciones" :state="getValidationState(validationContext)" placeholder="Ingrese la observacion" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12" class="text-right my-2">
                        <b-button  v-if=" checkPermissions('013-TAR-CAL','u')==1"  size="md" :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarTarea= false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

import {
    CChartPie
} from '@coreui/vue-chartjs'

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'

export default {
    components: {
        FullCalendar,
        CChartPie
    },
    data() {
        return {
            disabled: false,
            disabledRG: false,
            calenFrecuen: 1,
            labelEstadistica: ["Pendientes", "Realizadas"],
            optionsEstadistica: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },
            listaTareas: [],
            camposTareasPendientes: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "empresa",
                    label: "Empresa",
                    class: "text-center",
                },
                {
                    key: "localidad",
                    label: "Localidad",
                    class: "text-center",
                },
                {
                    key: "contacto",
                    label: "Contacto",
                    class: "text-center",
                },
                {
                    key: "telefono",
                    label: "Teléfono",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Tarea",
                    class: "text-center",
                },
                {
                    key: "fechaInicio",
                    label: "Fecha de inicio",
                    class: "text-center",
                },

                {
                    key: "prioridad",
                    label: "Prioridad",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],
            camposTareasRealizadas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "empresa",
                    label: "Empresa",
                    class: "text-center",
                },
                {
                    key: "localidad",
                    label: "Localidad",
                    class: "text-center",
                },
                {
                    key: "contacto",
                    label: "Contacto",
                    class: "text-center",
                },
                {
                    key: "telefono",
                    label: "Teléfono",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Tarea",
                    class: "text-center",
                },
                {
                    key: "fechaInicio",
                    label: "Fecha de inicio",
                    class: "text-center",
                },

                {
                    key: "prioridad",
                    label: "Prioridad",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],

            datosTarea: {
                idCliente: '',
                nombre: '',
                empresa: '',
                localidad: '',
                contacto: '',
                email: '',
                telefono: '',
                gtm: this.getGMT(),
                diaCompleto: true,
                subEstado: null,
                prioridad: null,
                observaciones: '',
                horaInicio: '',
                horaFin: '',
                fechaInicio: moment().format('YYYY-MM-DD'),
                fechaFin: moment().format('YYYY-MM-DD'),
            },

            datosActualizarTarea: {
                idTarea: '',
                idCliente: '',
                nombre: '',
                empresa: '',
                localidad: '',
                contacto: '',
                email: '',
                telefono: '',
                gtm: this.getGMT(),
                diaCompleto: true,
                subEstado: null,
                prioridad: null,
                observaciones: '',
                horaInicio: '',
                horaFin: '',
                fechaInicio: '',
                fechaFin: '',
            },

            comboEstado: [{
                    idEstado: 1,
                    descripcion: 'Pendiente',
                },
                {
                    idEstado: 2,
                    descripcion: 'Realizada',
                },
            ],
            comboPrioridad: [{
                    idPrioridad: 1,
                    descripcion: 'Baja'
                },
                {
                    idPrioridad: 2,
                    descripcion: 'Normal'
                }, {
                    idPrioridad: 3,
                    descripcion: 'Alta'
                }
            ],

            radioDiaCompleto: [{
                    text: 'SI',
                    value: true
                },
                {
                    text: 'NO',
                    value: false
                }
            ],

            calendarOptions: {
                // headerToolbar: {
                //     left: 'prevYear,prev,next,nextYear,today',
                //     center: 'title',
                //     right: 'dayGridMonth,timeGridWeek,timeGridDay'
                // },
                headerToolbar: {
                    left: 'prev,next',
                    center: 'title',
                    right: ''
                },
                views: {
                    value: {
                        text: 'list'
                    },
                    agendaDay: {
                        buttonText: 'time line'
                    },
                },

                // headerToolbar: {
                //     left: 'dayGridMonth,timeGridWeek,timeGridDay today',
                //     center: 'title',
                //     right: ' prevYear,prev,next,nextYear'
                // },
                plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
                //dateClick: this.handleDateClick,
                weekends: true,
                selectable: true,
                initialView: 'dayGridMonth',
                selectMirror: true,
                events: [],
                locale: 'es',

                editable: false,
                // dayMaxEvents: true,
                select: this.abrirModalRegistrarTarea,
                eventClick: this.abrirModalActualizarTarea1,
                // eventsSet: this.handleEvents

                //eventAdd:
                //eventChange: 
                // eventRemove: this.EliminarEvento,

            },

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,

            filasTotales2: 1,
            paginaActual2: 1,
            porPagina2: 10,

            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            filter2: null,
            filterOn2: [],

            modalRegistrarTarea: false,
            modalActualizarTarea: false,

        }

    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        getGMT() {
            let gmt = moment().format('Z').substr(1, 10)
            return gmt;
        },
        validarTimes() {
            if (this.datosTarea.horaInicio != '' && this.datosTarea.horaFin != '')
                if (this.datosTarea.horaInicio > this.datosTarea.horaFin) {
                    this.swat('error', 'La hora de inicio no debe ser mayor');
                    this.datosTarea.horaInicio = '';
                }
        },

        CambiarFrencuencia(num) {
            let calendarApi = this.$refs.calendar.getApi()
            this.calenFrecuen = num;
            if (num == 1) {
                calendarApi.changeView('dayGridMonth');
            } else if (num == 2) {
                calendarApi.changeView('timeGridWeek')
            } else if (num == 3) {
                calendarApi.changeView('timeGridDay')
            }
        },

        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        onFiltered2(itemsFiltrados) {
            this.filasTotales2 = itemsFiltrados.length
            this.paginaActual2 = 1
        },
        registrarTarea() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-tarea",
                    me.datosTarea, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalRegistrarTarea = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarTareas();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });
        },
        listarTareas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-tareas", {
                        params: {
                            idCliente: me.datosTarea.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaTareas = response.data
                    me.filasTotales = response.data.filter(x => x.subEstado == 1).length;
                    me.filasTotales2 = response.data.filter(x => x.subEstado == 2).length;
                    me.calendarOptions.events = [];
                    for (let i in response.data) {
                        let colorx;
                        switch (response.data[i].prioridad) {
                            case 1:
                                colorx = '#b0b0b0'
                                break;
                            case 2:
                                colorx = '#5e96ea'
                                break;
                            case 3:
                                colorx = '#ec5b58'
                                break;
                            default:
                                colorx = '#000000'
                                break;
                        }

                        me.calendarOptions.events.push({
                            id: response.data[i].idTarea,
                            title: response.data[i].nombre,
                            start: response.data[i].inicio,
                            end: response.data[i].fin,
                            allDay: response.data[i].diaCompleto,
                            color: colorx
                        })
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarTarea() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-tarea",
                    me.datosActualizarTarea, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalActualizarTarea = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarTareas();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });
        },
        abrirModalActualizarTarea(param) {
            let me = this;
            me.datosActualizarTarea.idTarea = param.item.idTarea;
            me.datosActualizarTarea.idCliente = param.item.idCliente;
            me.datosActualizarTarea.nombre = param.item.nombre;
            me.datosActualizarTarea.empresa = param.item.empresa;
            me.datosActualizarTarea.localidad = param.item.localidad;
            me.datosActualizarTarea.contacto = param.item.contacto;
            me.datosActualizarTarea.email = param.item.email;
            me.datosActualizarTarea.telefono = param.item.telefono;
            me.datosActualizarTarea.subEstado = param.item.subEstado;
            me.datosActualizarTarea.prioridad = param.item.prioridad;
            me.datosActualizarTarea.observaciones = param.item.observaciones;

            me.datosActualizarTarea.diaCompleto = param.item.diaCompleto == 1 ? true : false;

            if (param.item.diaCompleto == 1) {
                me.datosActualizarTarea.fechaInicio = param.item.inicio;
                me.datosActualizarTarea.fechaFin = param.item.fin;
                me.datosActualizarTarea.horaInicio = '';
                me.datosActualizarTarea.horaFin = '';
            } else if (param.item.diaCompleto == 0) {
                me.datosActualizarTarea.horaInicio = param.item.inicio.split("T")[1].split("-")[0];
                me.datosActualizarTarea.horaFin = param.item.fin.split("T")[1].split("-")[0];
                me.datosActualizarTarea.fechaInicio = param.item.inicio.substr(0, 10)
                me.datosActualizarTarea.fechaFin = param.item.fin.substr(0, 10)
            }
            this.modalActualizarTarea = true;
        },

        resetModalRegistrarTarea() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.disabledRG = false;

            this.datosTarea.nombre = '';
            this.datosTarea.empresa = '';
            this.datosTarea.localidad = '';
            this.datosTarea.contacto = '';
            this.datosTarea.email = '';
            this.datosTarea.telefono = '';

            this.datosTarea.gtm = this.getGMT();
            this.datosTarea.diaCompleto = true;
            this.datosTarea.subEstado = null;
            this.datosTarea.prioridad = null;
            this.datosTarea.observaciones = '';
            this.datosTarea.horaInicio = '';
            this.datosTarea.horaFin = '';
            this.datosTarea.fechaInicio = moment().format('YYYY-MM-DD');
            this.datosTarea.fechaFin = moment().format('YYYY-MM-DD');
        },
        resetModalActualizarTarea() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

        },
        actualizarEstadoTarea(param) {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/actualizar-estado-tarea", {
                        idTarea: param.item.idTarea
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarTareas();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarTarea(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la tarea?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-tarea", {
                                idTarea: param.item.idTarea
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarTareas();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

        abrirModalRegistrarTarea(selectInfo) {
            let me = this;

            me.datosTarea.diaCompleto = selectInfo.allDay
            me.disabledRG = true

            if (selectInfo.allDay) {
                me.datosTarea.fechaInicio = selectInfo.startStr
                me.datosTarea.fechaFin = selectInfo.endStr
            } else {
                me.datosTarea.fechaInicio = selectInfo.startStr.substr(0, 10);
                me.datosTarea.fechaFin = selectInfo.endStr.substr(0, 10)

                me.datosTarea.gtm = selectInfo.startStr.split("-")[3]

                me.datosTarea.horaInicio = selectInfo.startStr.split("T")[1].split("-")[0];
                me.datosTarea.horaFin = selectInfo.endStr.split("T")[1].split("-")[0];
            }

            me.modalRegistrarTarea = true

        },
        abrirModalActualizarTarea1(clickInfo) {
            let me = this;
            let TareaSeleccionada = me.listaTareas.filter(x => x.idTarea == clickInfo.event.id);
            me.datosActualizarTarea.idTarea = clickInfo.event.id;
            me.datosActualizarTarea.idCliente = TareaSeleccionada[0].idCliente;
            me.datosActualizarTarea.nombre = TareaSeleccionada[0].nombre;
            me.datosActualizarTarea.empresa = TareaSeleccionada[0].empresa;
            me.datosActualizarTarea.localidad = TareaSeleccionada[0].localidad;
            me.datosActualizarTarea.contacto = TareaSeleccionada[0].contacto;
            me.datosActualizarTarea.email = TareaSeleccionada[0].email;
            me.datosActualizarTarea.telefono = TareaSeleccionada[0].telefono;
            me.datosActualizarTarea.subEstado = TareaSeleccionada[0].subEstado;
            me.datosActualizarTarea.prioridad = TareaSeleccionada[0].prioridad;
            me.datosActualizarTarea.observaciones = TareaSeleccionada[0].observaciones;

            me.datosActualizarTarea.diaCompleto = TareaSeleccionada[0].diaCompleto == 1 ? true : false;

            if (TareaSeleccionada[0].diaCompleto == 1) {
                me.datosActualizarTarea.fechaInicio = TareaSeleccionada[0].inicio;
                me.datosActualizarTarea.fechaFin = TareaSeleccionada[0].fin;
                me.datosActualizarTarea.horaInicio = '';
                me.datosActualizarTarea.horaFin = '';
            } else if (TareaSeleccionada[0].diaCompleto == 0) {
                me.datosActualizarTarea.horaInicio = TareaSeleccionada[0].inicio.split("T")[1].split("-")[0];
                me.datosActualizarTarea.horaFin = TareaSeleccionada[0].fin.split("T")[1].split("-")[0];
                me.datosActualizarTarea.fechaInicio = TareaSeleccionada[0].inicio.substr(0, 10)
                me.datosActualizarTarea.fechaFin = TareaSeleccionada[0].fin.substr(0, 10)
            }

            me.modalActualizarTarea = true;

        },

    },
    computed: {
        dataSetsEstadistica() {
            return [{
                borderWidth: 3,
                backgroundColor: [
                    '#428bca94',
                    '#ccccccdb',
                ],
                data: [this.listaTareas.filter(x => x.subEstado == 1).length, this.listaTareas.filter(x => x.subEstado == 2).length]
            }]
        }
    },
    watch: {
        modalRegistrarTarea: function (val) {
            if (val == false) {
                this.resetModalRegistrarTarea();
            }
        },
        modalActualizarTarea: function (val) {
            if (val == false) {
                this.resetModalActualizarTarea();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosTarea.idCliente = this.datosActualizarTarea.idCliente = user.uidClient;
            this.listarTareas();
        }
    }

}
</script>
